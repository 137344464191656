<template>
  <b-modal
      centered
      ref="modal"
      hide-footer
      id="modal-edit-cutoff"
      title="Edit CutOff Information"
      hide-backdrop
  >
    <div>
      <b-form @submit="onSubmit">
        <p>cutoff id: {{ selectedId.id }}</p>
        <b-form-group
            id="input-group-1"
            label="CutOff-Time:"
            label-for="input-1"
        >
          <b-form-input
              id="input-1"
              v-model="selectedId.cut_off_time"
              type="text"
              placeholder="Enter New Time"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Effect:" label-for="input-2">
          <b-form-select
              v-model="selectedId.effect"
              :options="optionsEffect"
          ></b-form-select>
        </b-form-group>

        <b-form-group
            id="input-group-2"
            label="Day-Of-Week:"
            label-for="input-3"
        >
          <b-form-select
              v-model="selectedId.day_of_week"
              :options="options"
          ></b-form-select>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["selectedId", "selectedRegion"],
  data() {
    return {
      options: [
        {value: "Sunday", text: "Sunday"},
        {value: "Monday", text: "Monday"},
        {value: "Tuesday", text: "Tuesday"},
        {value: "Wednesday", text: "Wednesday"},
        {value: "Thursday", text: "Thursday"},
        {value: "Friday", text: "Friday"}
      ],
      optionsEffect: [
        {value: "SAME_DAY", text: "Same-Day"},
        {value: "NEXT_DAY", text: "Next-Day"},
        {value: "NEXT_DAY_1", text: "Next-Day + 1"},
        {value: "NEXT_DAY_2", text: "Next-Day + 2"},
        {value: "NEXT_DAY_3", text: "Next-Day + 3"}
      ]
    };
  },
  methods: {
    async onSubmit(e) {

      e.preventDefault();
      try {
        const result = await this.axios.put(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT + `/cutoff/default`,
            {
              // eslint-disable-next-line @typescript-eslint/camelcase
              default_cutoff_uuid: this.selectedId.id,
              // eslint-disable-next-line @typescript-eslint/camelcase
              cutoff_time: this.selectedId.cut_off_time,
              // eslint-disable-next-line @typescript-eslint/camelcase
              cutoff_effect: this.selectedId.effect,
              // eslint-disable-next-line @typescript-eslint/camelcase
              day_of_week: this.selectedId.day_of_week
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.idToken
              }
            }
        );

        (result && result.status == 200) ? this.displayMessage('success',
            `${this.selectedRegion} :: ${result.data}`) : (result.data) ?
            this.displayMessage('warning', `${this.selectedRegion} :: ${result.data}`) :
            this.displayMessage('warning', `${this.selectedRegion} :: Error updating cutoff time.`);

      } catch (err) {
        console.error(err);
      }
      this.$refs.modal.hide();
    },
    show() {
      this.$refs.modal.show();
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 10000,
        solid: true
      });
    }
  }
};
</script>

<style></style>
