import Vue from "vue";
import VueRouter from "vue-router";

import App from "@/App.vue";
import Home from "@/views/Home.vue";
import DriverSchedule from "@/views/delivery/DriverSchedule.vue";
import ZoneDeliveryRate from "@/views/delivery/ZoneDeliveryRate.vue";
import DeliveryRate from "@/views/delivery/DeliveryRate.vue";
import UserLogin from "@/views/login/UserLogin.vue";
import NotFound from "@/views/common/NotFound.vue";
import Dashboard from "@/views/common/Dashboard.vue";
import LoginTransit from "@/views/login/LoginTransit.vue";
import ManualOrderFulfillment from "@/views/order/ManualOrderFulfillment.vue";
import CutOffTimeTable from "@/views/order/cutoff-time-table.vue";
import LatestOrderPulls from "@/views/order/LatestOrderPulls.vue";
import PassThrough from "@/views/common/PassThrough.vue";
import OrderReports from "@/views/reports/OrderReports.vue";
import OrderPullAutomation from "@/views/reports/OrderPullAutomation.vue";
import ReportsList from "@/views/reports/ReportsList.vue";
import RulesConfig from "@/views/reports/RulesConfig.vue";
import RulesList from "@/views/reports/RulesList.vue";
import QuickReports from "@/views/reports/QuickReports.vue";
import BulkPrePulls from "@/views/reports/BulkPrePulls.vue";
import Sms from "@/views/sms/Sms.vue";
import UnsubscribeList from "@/views/unsubscribe/UnsubscribeList.vue";
import UnsubscribeEmail from "@/views/unsubscribe/UnsubscribeEmail.vue";
import CustomerList from "@/views/unsubscribe/CustomerList.vue";
import Greets from "@/views/greets/Greets.vue";
import OpsLinks from "@/views/ops/OpsLinks.vue";
import OpsCreds from "@/views/ops/OpsCreds.vue";
import CalliaExcelToPdf from "@/views/exporter/CalliaExcelToPdf.vue";
import CalliaWordToPdf from "@/views/exporter/CalliaWordToPdf.vue";
import CalliaGiftBox from "@/views/exporter/CalliaGiftBox.vue";
import ExporterProcessReport from "@/views/exporter/ExporterProcessReport.vue";
import Region from "@/views/region/RegionDashboard.vue";
import PostalCode from "@/views/postalcode/PostalCodeDashboard.vue";
import Town from "@/views/town/TownDashboard.vue";
import BulkOrder from "@/views/b2borderautomation/b2bordersubmission.vue";
import Zone from "@/views/zone/ZoneDashboard.vue";
import OnfleetDriversTaskToday from "@/views/driversmshub/onfleetDriversTaskToday.vue";
import {navigationGuard} from "./callia-okta-authenticate";
import FailedOrder from "@/views/b2borderautomation/b2bfailedordersubmission.vue"
import DeliveryNotificationDashboard from "@/views/deliverynotification/DeliveryNotificationDashboard.vue";
import GreetBulk from "@/views/greets/CreateBulkOrder.vue";
import GreetList from "@/views/greets/GreetList.vue";
import GiftsetTemplateDashboard from "@/views/giftset-alert-template/GiftsetTemplateDashboard.vue";
import GiftSetsAlert from "@/views/giftsetsalert/GiftSetsAlertDashboard.vue";
import ReplacementOrderAutomation from "@/views/order/ReplacementOrderAutomation.vue";
import CheckoutAutomation from "@/views/order/CheckoutAutomation.vue";
import BulkOrderLinkCreator from "@/views/order/BulkOrderLinkCreator.vue";
import UpgradeAutomation from "@/views/order/UpgradeAutomation.vue";
import MassAdjustTags from "@/views/order/MassAdjustTags.vue";
import DatesClosed from "@/views/rules/DatesClosed.vue";
import DatesAvailable from "@/views/rules/DatesAvailable.vue";

Vue.use(VueRouter);

const router = new VueRouter({
        mode: "history",
        routes: [
            // { path: "/login", component: UserLogin },
            {
                path: "",
                component: LoginTransit,
                meta: {
                    noAuth: true
                }
            },
            {
                path: "/login",
                component: UserLogin,
                meta: {
                    noAuth: true
                }
            },
            {path: "/logout", redirect: "/"},
            // {
            //   path: "/transit",
            //   component: LoginTransit
            // },
            {
                path: "*",
                component: NotFound
            },
            {
                path: "/secure",
                component: Home,
                children: [
                    {
                        path: ":userId/dashboard",
                        component: Dashboard
                    },
                    {
                        path: "orders",
                        component: PassThrough,
                        children: [
                            {
                                path: "manual-fullfillment",
                                component: ManualOrderFulfillment
                            },
                            {
                                path: "cutoffs",
                                component: CutOffTimeTable
                            },
                            {
                                path: "bulkorder",
                                component: BulkOrder
                            },
                            {
                                path: "failedorder",
                                component: FailedOrder
                            },
                            {
                                path: "latest-order-pulls",
                                component: LatestOrderPulls
                            },
                            {
                                path: "replacement-order-automation",
                                component: ReplacementOrderAutomation
                            },
                            {
                                path: "checkout-automation",
                                component: CheckoutAutomation
                            },
                            {
                                path: "bulk-order-link-creator",
                                component: BulkOrderLinkCreator
                            },
                            {
                                path: "upgrade-automation",
                                component: UpgradeAutomation
                            },
                            {
                                path: "mass-adjust-tags",
                                component: MassAdjustTags
                            }
                        ]
                    },
                    {
                        path: "rules",
                        component: PassThrough,
                        children: [
                            {
                                path: "dates-closed",
                                component: DatesClosed
                            },
                            {
                                path: "dates-available",
                                component: DatesAvailable
                            }
                        ]
                    },
                    {
                        path: "ops",
                        component: PassThrough,
                        children: [
                            {
                                path: "links",
                                component: OpsLinks
                            },
                            {
                                path: "creds",
                                component: OpsCreds
                            }
                        ]
                    },
                    {
                        path: "schedule",
                        component: PassThrough,
                        children: [
                            {
                                path: "drivers",
                                component: DriverSchedule
                            }
                        ]
                    },
                    {
                        path: "reports",
                        component: PassThrough,
                        children: [
                            {
                                path: "order-reports",
                                name: 'OrderReports',
                                component: OrderReports
                            },
                            {
                                path: "reports-list",
                                name: 'ReportsList',
                                component: ReportsList
                            },
                            {
                                path: "pull-automation",
                                name: 'OrderPullAutomation',
                                component: OrderPullAutomation
                            },
                            {
                                path: "rules-config",
                                name: 'RulesConfig',
                                component: RulesConfig
                            },
                            {
                                path: "rules-list",
                                name: 'RulesList',
                                component: RulesList
                            },
                            {
                                path: "quick-reports",
                                component: QuickReports
                            },
                            {
                                path: "bulk-pre-pulls",
                                component: BulkPrePulls
                            }
                        ]
                    },
                    {
                        path: "sms",
                        component: PassThrough,
                        children: [
                            {
                                path: "history",
                                component: Sms
                            }
                        ]
                    },
                    {
                        path: "unsubscribe",
                        component: PassThrough,
                        children: [
                            {
                                path: "phone",
                                component: UnsubscribeList
                            },
                            {
                                path: "email",
                                component: UnsubscribeEmail
                            },
                            {
                                path: "customer-list",
                                component: CustomerList
                            },
                            {
                                path: "phone",
                                component: UnsubscribeList
                            },
                            {
                                path: "email",
                                component: UnsubscribeEmail
                            }
                        ]
                    },
                    {
                        path: "greets",
                        component: PassThrough,
                        children: [
                            {
                                path: "add-greets",
                                component: Greets
                            },
                            {
                                path: "bulk-greets",
                                component: GreetBulk
                            },
                            {
                                path: "greet-list",
                                component: GreetList
                            }
                        ]
                    },
                    {
                        path: "region",
                        component: Region
                    },
                    {
                        path: "postalcode",
                        component: PostalCode
                    },
                    {
                        path: "zone-delivery-rate",
                        component: ZoneDeliveryRate
                    },
                    {
                        path: "delivery-rate",
                        component: DeliveryRate
                    },
                    {
                        path: "exporter",
                        component: PassThrough,
                        children: [
                            {
                                path: "callia-excel-to-pdf",
                                component: CalliaExcelToPdf
                            },
                            {
                                path: "callia-gift-box",
                                component: CalliaGiftBox
                            },
                            {
                                path: "callia-word-to-pdf",
                                component: CalliaWordToPdf
                            },
                            {
                                path: "export-process-report",
                                component: ExporterProcessReport
                            }
                        ]
                    },
                    {
                        path: "zone",
                        component: Zone
                    },
                    {
                        path: "mass-sms-hub",
                        component: PassThrough,
                        children: [
                            {
                                path: "onfleet-drivers-today",
                                component: OnfleetDriversTaskToday
                            }
                        ]
                    },

                    {
                        path: "town",
                        component: Town
                    },
                    {
                        path: "delivery-notification/SMS",
                        component: DeliveryNotificationDashboard
                    },
                    {
                        path: "giftset/template",
                        component: GiftsetTemplateDashboard
                    },
                    /*{
                        path: "giftsetsalert",
                        component: PassThrough,
                        children: [
                            {
                                path: "giftsetsalert",
                                component: GiftSetsAlert
                            },

                        ]
                    }*/
                    {
                        path: "giftset/alert",
                        component: GiftSetsAlert
                    },
                    {
                        path: "town",
                        component: Town
                    },
                    {
                        path: "delivery-notification/SMS",
                        component: DeliveryNotificationDashboard
                    },


                ]
            }
        ]
    })
;
router.beforeEach(navigationGuard)
export default router;
