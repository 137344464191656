<template>
  <div>
    <b-overlay rounded="sm">
      <h1>Dashboard</h1>
    </b-overlay>

    <div class="cardContainer">
      <b-card class="cardContent">
        <img
            src="@/assets/images/dashboard/orders.svg"
            class="image"
        />
        <b-card-title class="custom-class">Orders</b-card-title>
        <ul>
          <li>
            <router-link
                class="nav-links"
                to="/secure/orders/manual-fullfillment"
            >Manual Fulfillment
            </router-link>
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/orders/replacement-order-automation"
            >Order Replacement Automation
            </router-link>
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/orders/checkout-automation"
            >Checkout Automation
            </router-link>
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/orders/bulk-order-link-creator"
            >Bulk Order Link Creator
            </router-link>
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/orders/upgrade-automation"
            >Upgrade Automation
            </router-link>
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/orders/mass-adjust-tags"
            >Mass Adjust Tags
            </router-link>
          </li>
          <li>
            <router-link class="nav-links" to="/secure/reports/pull-automation"
            >Order Adjustments
            </router-link
            >
          </li>
        </ul>
      </b-card>
      <b-card class="cardContent">
        <img
            src="@/assets/images/dashboard/labels.svg"
            class="image"
        />
        <b-card-title class="custom-class">Callia Rules</b-card-title>
        <ul>
          <li>
            <router-link class="nav-links" to="/secure/orders/cutoffs"
            >CutOffs
            </router-link>
          </li>
          <li>
            <router-link class="sidebar-link" to="/secure/rules/dates-closed"
            >Dates Closed
            </router-link
            >
          </li>
          <li>
            <router-link class="sidebar-link" to="/secure/rules/dates-available"
            >Dates Available
            </router-link
            >
          </li>
        </ul>
      </b-card>
      <b-card class="cardContent">
        <img
            src="@/assets/images/dashboard/generate.svg"
            class="image"
        />
        <b-card-title class="custom-class">Order Pulls</b-card-title>
        <ul>
          <li>
            <router-link class="nav-links" to="/secure/reports/reports-list"
            >Order Pulls Config
            </router-link
            >
          </li>
          <li>
            <router-link class="nav-links" to="/secure/reports/rules-list"
            >Order Pulls Schedules list
            </router-link
            >
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/orders/latest-order-pulls"
            >Latest OrderPulls
            </router-link
            >
          </li>
        </ul>
      </b-card>
      <b-card class="cardContent">
        <img
            src="@/assets/images/dashboard/gift.svg"
            class="image"
        />
        <b-card-title class="custom-class">Greets</b-card-title>
        <ul>
          <li>
            <router-link class="nav-links" to="/secure/greets/add-greets"
            >Add Greet
            </router-link
            >
          </li>
          <li>
            <router-link class="nav-links" to="/secure/greets/greet-list">Greet List</router-link>
          </li>
        </ul>
      </b-card>
      <b-card class="cardContent">
        <img
            src="@/assets/images/dashboard/operation.svg"
            class="image"
        />
        <b-card-title class="custom-class">Operation</b-card-title>
        <ul>
          <li>
            <router-link
                class="nav-links"
                to="/secure/unsubscribe/phone"
            >Unsubscribe Phone
            </router-link
            >
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/unsubscribe/email"
            >Unsubscribe Email
            </router-link
            >
          </li>

          <li>
            <router-link class="sidebar-link" to="/secure/unsubscribe/customer-list"
            >Edit Customer List</router-link>
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/sms/history"
            >SMS History
            </router-link
            >
          </li>
        </ul>
      </b-card>
      <b-card class="cardContent">
        <img
            src="@/assets/images/dashboard/labels.svg"
            class="image"
        />
        <b-card-title class="custom-class">Labels</b-card-title>
        <ul>
          <li>
            <router-link
                class="nav-links"
                to="/secure/exporter/callia-excel-to-pdf"
            >Callia Excel to PDF
            </router-link
            >
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/exporter/callia-gift-box"
            >Curated Gift Box
            </router-link
            >
          </li>
          <li>
            <router-link
                class="nav-links"
                to="/secure/exporter/callia-word-to-pdf"
            >Word to PDF
            </router-link
            >
          </li>
        </ul>
      </b-card>
      <b-card class="cardContent">
        <img
            src="@/assets/images/dashboard/tools.svg"
            class="image"
        />
        <b-card-title class="custom-class">Tools</b-card-title>
        <ul>
          <li>
            <router-link class="nav-links" to="/secure/ops/links"
            >Ops Links
            </router-link
            >
          </li>
          <li>
            <router-link class="nav-links" to="/secure/ops/creds/"
            >Ops Credentials
            </router-link
            >
          </li>
        </ul>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  TruckIcon,
  PackageIcon,
  FileTextIcon,
  GiftIcon
} from "vue-feather-icons";

export default {
  components: {}
};
</script>

<style>
.cardContainer {
  display: grid;
  grid-gap: 20px;
}

.cardContent {
  text-align: center;
  background-color: #fdfdfd;
  color: #45494a;
  border-radius: 1rem !important;
  box-shadow: 2px 2px 6px #bbbbbb;
}

.cardContainer img {
  width: 45%;
  height: 150px;
}


/* remove this to unhide the delivery card */
#SchedulesMenu {
  display: none;
}

.cardContent ul {
  list-style: none;
  text-align: center;
  padding-right: 10px;
}

.cardContent ul li {
  padding-bottom: 0.1rem;
}

.cardContainer a {
  color: #6c757d;
}

.cardContainer a:hover {
  color: #b4bbc1;
}

.custom-class {
  padding: 0.3rem;
  margin: auto 0;
}

.nav-links {
  margin-right: 10px;
}

.cardContainer {
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1100px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    .cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (max-width: 600px) {
  .cardContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
