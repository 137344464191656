<template>
  <div>
    <b-row class="py-3 text-center">
      <b-col offset-lg="4" lg="4" sm="12">
        <b-input-group size="sm">
          <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
          ></b-form-input>

          <b-input-group-append>
            <b-icon icon="search" class="icon"></b-icon>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col offset-lg="3" lg="6" sm="12">
        <b-form-group
            class="mb-0"
            v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="my-1 py-1 custom-filter-bg"
          >
            <b-form-checkbox value="cutOff">Cut-Off-Time</b-form-checkbox>
            <b-form-checkbox value="region">Region</b-form-checkbox>
            <b-form-checkbox value="city.cityName">City</b-form-checkbox>
            <b-form-checkbox value="effect">Effect</b-form-checkbox>
            <b-form-checkbox value="dayOfWeek">Day</b-form-checkbox>
          </b-form-checkbox-group>
          <b-badge variant="light">Type and press enter to filter data.
          </b-badge>
        </b-form-group>
      </b-col>
    </b-row>
    <b-col sm="5" md="6" class="my-1 selectPage">
          <overrideModal
              v-bind:selectedOverride="selectedOverride"
              ref="overrideModal"
          />
    </b-col>
    <b-row class="mt-3">
      <b-col lg="2" sm="6" class="left pr-0 mr-0">
        <h6 class="pagination-details">Per page
          <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
          ></b-form-select>
        </h6>
      </b-col>
      <b-col sm="7" md="4" class="my-1 right-float">
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <!--    <b-col sm="7" md="6" class="my-1">-->
    <!--      <b-pagination-->
    <!--        v-model="currentPage"-->
    <!--        :total-rows="totalRows"-->
    <!--        :per-page="perPage"-->
    <!--        align="fill"-->
    <!--        size="sm"-->
    <!--        class="my-0"-->
    <!--      ></b-pagination>-->
    <!--    </b-col>-->
    <b-table
        :items="items"
        :fields="fields"
        striped
        empty-text="No Data Available"
        responsive="sm"
        :per-page="perPage"
        class="table"
        :busy="isBusy"
        :current-page="currentPage"
        outlined
        sticky-header="true"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner
              type="grow"
              variant="info"
              label="Loading..."
              class="align-middle"
          ></b-spinner>
          <strong class="loadingLabel">Loading...</strong>
        </div>
      </template>

      <template #cell(region)="data">
        <div class="cityModel">
          <b-button @click="data.toggleDetails" @focus="getSelectedRegion(data.item.region)"
                    class="citybtn mr-2">
            {{ data.item.region }}
            <b-icon class="dropDownIcon right-float" icon="caret-down-fill"></b-icon>
          </b-button>
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        </div>
      </template>

      <template #row-detail="data">
        <b-card>
          <ul>
            <li v-for="(value, key) in data.items" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>

      <template #cell(action)="data">
        <div class="middle">
          <b-button @click="overrideData(data.item)" class="Overide-btn"
          >View-Overrides
          </b-button
          >
        </div>
      </template>

      <template #cell(province)="data">
        <div class="province">
          {{ data.item.province }}
        </div>
      </template>

      <template #row-details="data">
        <div>
          <b-card>
            <b-table
                :items="data.item.default_cutoffs"
                :fields="innerField"
                class="middle"
                empty-text="No Data Available"
                show-empty
                striped
                responsive="sm"
                sticky-header="true"
                hover
            >
              <template #cell(DayOfWeek)="data">
                <div class="dayOfWeek">
                  {{ data.item.day_of_week }}
                </div>
              </template>

              <template #cell(cutOffTime)="data">
                <div class="cutofftime">
                  {{ data.item.cut_off_time }}
                </div>
              </template>

              <template #cell(effect)="data">
                <b-badge
                    :class="
                    data.item.effect === 'SAME_DAY' ? 'Same-Day' : data.item.effect === 'NEXT_DAY'?
                     'Next-Day' : 'Next-Day-plus'
                  "
                >
                  {{
                    data.item.effect === "SAME_DAY" ? "Same-Day" : data.item.effect === "NEXT_DAY"?
                  "Next-Day" : data.item.effect === "NEXT_DAY_1"? "Next-Day + 1": data.item.effect === "NEXT_DAY_2"?
                  "Next-Day + 2": "Next-Day + 3"
                  }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <edit-icon
                    size="1.5x"
                    class="custom-class"
                    @click="editData(data.item)"
                ></edit-icon>
                <x-icon size="1.5x" class="custom-class" id="x-icon"></x-icon>
              </template>
            </b-table>
            <b-button class="hideDetails" size="sm" @click="data.toggleDetails"
            >Hide Details
            </b-button
            >
          </b-card>
          <editModal ref="modalComponent" v-bind:selectedId="selectedId"
                     v-bind:selectedRegion="selectedRegion"/>
        </div>
      </template>
    </b-table>
    <b-row class="mt-3">
      <b-col lg="2" sm="6" class="left pr-0 mr-0">
        <h6 class="pagination-details">Per page
          <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
          ></b-form-select>
        </h6>
      </b-col>
      <b-col sm="7" md="4" class="my-1 right-float">
        <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {EditIcon, XIcon} from "vue-feather-icons";
import editModal from "../cutoffedits/cutoff-edit-modal.vue";
import overrideModal from "../viewoverrides/view-overrides-modal.vue";

export default {
  data() {
    return {
      fields: [
        "region",
        {province: "Province"},
        {action: "Action"}
      ],
      innerField: [
        {DayOfWeek: "DayOfWeek"},
        {cutOffTime: "CutOffTime"},
        {effect: "Effect"},
        {actions: "Action"}
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      selectedId: [],
      selectedOverride: [],
      pageOptions: [5, 10, 15, {value: 100, text: "Show More"}],
      isBusy: true,
      items: [],
      filter: null,
      filterOn: [],
      sortBy: "",
      selectedRegion: ""
    };
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editData(id) {
      this.$refs.modalComponent.show();
      this.selectedId = id;
    },
    overrideData(overrideid) {
      this.selectedOverride = overrideid;
      this.$refs.overrideModal.show();
    },
    getSelectedRegion(region) {
      this.selectedRegion = region;
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  components: {
    EditIcon,
    XIcon,
    editModal,
    overrideModal
  },
  async created() {
    try {
      const res = await this.axios.get(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/cutoff/default/all",
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.idToken
            }
          }
      );
      const result = res.data;
      this.items = result;
      this.totalRows = this.items.length;
      this.isBusy = false;
    } catch (err) {
      console.error(err);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/cutoff-time-grid.scss";

td div button .btn-seondary {
  width: 10rem;
}
</style>
