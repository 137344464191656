<template>
  <b-modal
    id="modal-update-dates-available"
    ref="modal"
    :title="heading"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <div class="row mt-3">
        <div class="col-sm">
          <b-form-tags
            v-model="datesAvailable"
            type="text"
            placeholder="Enter New Date"
            required
          ></b-form-tags>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm">
          <b-form-input
            v-model="cutoff_time"
            type="text"
            maxlength="10"
            placeholder="Cutoff Time"
            class="input-height mt-1"
          ></b-form-input>
        </div>
        <div class="col-sm">
          <b-form-select
            v-model="cutoff_effect"
            :options="cutoffEffectsOpt"
            class="input-height mt-1"
          ></b-form-select>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>

export default {
  /** initialize props */
  props: ["name", "updateArray"],
  name: "edit-dates-available-popup",
  data() {
    return {
      datesAvailable: [],
      cutoff_time: "15:55",
      cutoff_effect: "1",
      datesAvailableResult: {},
      cutoffEffectsOpt: [
        { text: "SameDay", value: "0" },
        { text: "NextDay", value: "1" },
      ],
      heading: "",
      regionId: "",
      regionName: "",
      id: "",
    };
  },
  watch: {
    /** realtime props update */
    $props: {
      async handler(newVal, oldVal) {
        if (newVal?.updateArray) {
          console.log(`newVal: ${JSON.stringify(newVal, null, 2)}`);
          this.id = newVal.updateArray.id;
          this.regionName = newVal.updateArray.name;
          this.heading = `Update Region: ${newVal.updateArray.name}`;
          this.regionId = newVal.updateArray.region_id;
          console.log("available_date:", newVal.updateArray.available_date);
          this.datesAvailable = newVal.updateArray.available_date ? newVal.updateArray.available_date.split(",") : [];
          this.cutoff_time = newVal.updateArray.cutoff_time;
          this.cutoff_effect = newVal.updateArray.cutoff_effect;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async show() {
      this.$refs.modal.show();
    },

    resetModal() {
      console.log();
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 10000,
        solid: true,
      });
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      this.$emit("message", 'Updating ...');
      try {
        const result = await this.axios.put(
          process.env.VUE_APP_API_ADMIN_API_ENDPOINT +
            `/region-rules/${this.regionId}/rule`,
          {
            type: "datesAvailable",
            availableDates: this.datesAvailable.join(),
            cutoffTime: this.cutoff_time,
            cutoffEffect: this.cutoff_effect,
          }
        );
        this.$emit("success", 'success');
      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
      this.$refs.modal.hide();
    }
  }
};
</script>
