<template>
  <div>
    <CLAlert :show="alert.status" :color="alert.color" :message="alert.message"/>

    <div class="container-fluid p-0" id="order-reports">
      <div v-if="showProgress" class="progress-flex">
        <div class="progress-container">
          <CLProgress :value="progressBarWidth" animated/>
          <p class="mt-2 text-center">Exporting file. Please wait</p>
        </div>
      </div>

      <div class="row">
        <div class="col-auto">
          <CLHeader mainTopic="Callia labels" subTopic="Excel to PDF"/>
          <p class="mt-4 mb-0">All fields are required</p>
        </div>
      </div>

      <div class="row mt-3 mb-0 form">
        <div class="col-md-8 col-xl-6 col-12">
          <div class="form-card">
            <b-form id="order-report-form">

              <b-form-group>
                <label class="mt-2 report-label">Exported file name</label>
                <b-form-input
                  v-model="form.fileName"
                  type="text"
                  @keyup="validateFileName"
                  placeholder="exportFileName"
                  class="input-height mt-1"
                ></b-form-input>
              </b-form-group>

              <b-form-group class="mb-0 report-label" label="Number Of Pages" v-slot="{ ariaPageNumbers }">
                <b-form-radio v-model="form.pagesNum" :aria-describedby="ariaPageNumbers" name="pageNumberRadio"
                              value="0">
                  Default
                </b-form-radio>
                <b-form-radio v-model="form.pagesNum" :aria-describedby="ariaPageNumbers" name="pageNumberRadio"
                              value="2">
                  2 Pages
                </b-form-radio>
                <b-form-radio v-model="form.pagesNum" :aria-describedby="ariaPageNumbers" name="pageNumberRadio"
                              value="3">
                  3 Pages
                </b-form-radio>
              </b-form-group>

              <b-form-group class="mb-0 report-label" label="Driver Initials" v-slot="{ ariaOnfleet }">
                <b-form-radio v-model="form.onfleet" :aria-describedby="ariaOnfleet" name="onfleetRadio"
                              value="1">
                  Needs Driver Initials/OnFleet Deliveries
                </b-form-radio>
                <b-form-radio v-model="form.onfleet" :aria-describedby="ariaOnfleet" name="onfleetRadio"
                              value="0">
                  No driver initials/Courier deliveries
                </b-form-radio>
              </b-form-group>

              <b-form-group>
                <label class="mt-3 report-label">Orders per file (max)</label>
                <b-form-input
                  v-model="form.maxSize"
                  type="text"
                  @keyup="validateMaxSize"
                  placeholder="300"
                  class="input-height mt-1"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label class="mt-2 report-label">Excel file</label>
                <b-form-file
                  v-model="form.file"
                  placeholder="Choose a file"
                  class="input-height"
                ></b-form-file>
              </b-form-group>

              <b-form-group>
                <label class="mt-2 report-label">Result</label>
                <b-form-textarea
                  v-model="form.result"
                  rows="5"
                  max-rows="8"
                  plaintext
                  class="mt-1"
                ></b-form-textarea>
              </b-form-group>

              <CLButton
                class="mt-3"
                format="primary-btn"
                v-on:func="onSubmit"
                :disabled="!submitBtnValidate"
              >
                Export file
              </CLButton>

            </b-form>
          </div>
        </div>
      </div>
    </div>
    <div class="page-break"></div>
  </div>
</template>
<script>
import {CLProgress, CLHeader, CLButton, CLAlert} from 'callia-ui-box';
import {v4 as uuid} from 'uuid';

export default {
  components: {
    CLProgress,
    CLHeader,
    CLButton,
    CLAlert
  },
  data() {
    return {
      // labelsEndpoint: 'https://excel2pdf.callia.com/',
      labelsEndpoint: 'https://localhost/',
      /** initialize form fields */
      form: {
        /** initialize upload unique process id */
        processId: uuid(),
        fileName: '',
        result: '',
        pagesNum: 0,
        onfleet: 1,
        maxSize: '300',
        file: [],
      },
      /** initialize progress bar */
      progressBarWidth: 0,
      showProgress: false,
      /** initialize alert box */
      alert: {
        status: false,
        color: '',
        message: ''
      },
    }
  },
  methods: {
    /** form submit event */
    async onSubmit() {
      await this.excelToPdfExporter()
    },
    /** form filed validation */
    validateFileName(event) {
      const string = event.target.value
      this.form.fileName = string.replace(/\s+/g, "")
    },
    validateMaxSize(event) {
      const maxsize = event.target.value
      if (!maxsize.match(/^[1-9]+[0-9]*$/g)) {
        this.form.maxSize = ''
      }
    },
    /** excel to pdf upload main handler */
    async excelToPdfExporter() {
      this.alert.status = false
      this.showProgress = true

      this.form.processId = uuid()
      console.log("processId:", this.form.processId)
      const values = {
        processId: this.form.processId,
        exportFileName: this.form.fileName,
        pagesNum: this.form.pagesNum,
        onfleet: this.form.onfleet,
        maxSize: this.form.maxSize,
        file: this.form.file
      }

      const formData = new FormData()
      for (const key in values) {
        formData.append(key, values[key])
      }

      await this.axios({
        method: "post",
        url: this.labelsEndpoint + 'labels/upload',
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      })
      .then((response) => {
        this.loadProgressBar()
      })
      .catch(error => {
        console.log("error:", error);
        this.alert = {
          color: 'warning',
          message: 'File exported failed!',
          status: true
        }
        setTimeout(() => {
          this.alert.status = false
        }, 5500)
      });
    },
    /** form refresh */
    refreshPage() {
      this.showProgress = false
      this.progressBarWidth = 0
      this.alert.status = false
      this.form.fileName = ''
      this.form.pagesNum = 0
      this.form.maxSize = '300'
      this.form.file = []
      this.form.processId = uuid()
    },
    /** Load progressbar and calculate progress event  */
    async loadProgressBar() {
      let tempProgressValue = 0
      console.log("loadProgressBar processId:", this.form.processId)
      const progressRunner = setInterval(() => {
        this.axios.get(
          this.labelsEndpoint + 'labels/query?processId=' + this.form.processId,
        )
        .then(response => {
          const percentage = parseInt(response.data.percentage);
          if (percentage < 0) {
            clearInterval(progressRunner)
            this.showProgress = false
          } else {
            if (percentage === 100) {
              this.refreshPage()
              this.alert = {
                color: 'success',
                message: 'File exporting successfully done',
                status: true
              }
              setTimeout(() => {
                this.alert.status = false
              }, 5500)
              clearInterval(progressRunner)
            } else {
              if (parseInt(this.progressBarWidth) < 100) {
                this.progressBarWidth += 1
              }
            }
            if (tempProgressValue < percentage) {
              this.progressBarWidth = percentage
            }
            tempProgressValue = this.progressBarWidth
          }
          if (response.data.message) {
            this.form.result = response.data.message
            if (response.data.message.startsWith('Cannot')) {
              this.refreshPage()
              this.alert = {
                color: 'warning',
                message: 'Labels generation failed',
                status: true
              }
              setTimeout(() => {
                this.alert.status = false
              }, 5500)
              clearInterval(progressRunner)
            }
          } else {
            this.form.result = ""
          }
          if (response.data.excelRemote) {
            this.form.result += '\n' + response.data.excelRemote
          }
          if (response.data.wordRemote) {
            this.form.result += '\n' + response.data.wordRemote
          }
          if (response.data.pdfRemote) {
            this.form.result += '\n' + response.data.pdfRemote
          }
        }).catch(error => {
          console.log(error);
          clearInterval(progressRunner)
          this.showProgress = false
          this.form.result += 'Network Error. Please try again'
        });
      }, 1200)
    },
  },
  computed: {
    /** Submit button disable status validation by all field required. */
    submitBtnValidate() {
      if (this.form.fileName.trim() && this.form.maxSize &&
        this.form.file.name && /^[0-9]+$/.test(this.form.maxSize) && this.form.file.name.includes('.xlsx')) {
        return true
      } else {
        return false
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";
@import 'callia-ui-box/src/assets/scss/styles.scss';

.card {
  width: 100%;
  border: none;
}

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

#order-reports {
  position: relative;
}
</style>